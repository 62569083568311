/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { Box, Heading, ResponsiveContext } from "grommet"
import { Transition } from "../components/_shared_/transition"
import HeaderLayout from "../components/_shared_/header"
import { Container } from "../styles/components/container"
import GrommetWrapper from "../components/_shared_/grommet"
import SEO from "../components/_shared_/seo"
import FooterLayout from "../components/_shared_/footer"
import FadeIn from "../components/_shared_/fade-in"
import { mainBlue } from "../styles/constants"
import SlovaccLogoBlue from "../images/slovaccBlue.svg"
import Cookies from "../components/_shared_/cookies"

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <GrommetWrapper>
      <SEO title="Zrejme ste zablúdili | 404" />
      <Cookies />
      <ResponsiveContext.Consumer>
        {(size: string): JSX.Element => (
          <>
            <HeaderLayout size={size} />
            <Transition>
              <Container minHeight="auto">
                <Box
                  justify="center"
                  align="center"
                  height="calc(100vh + 82px)"
                  direction={size !== `small` ? `row` : `column`}
                >
                  <Box width={size !== `small` ? `30%` : `100%`}>
                    <FadeIn delay={250}>
                      <Heading size="xlarge" color={mainBlue}>
                        404
                      </Heading>
                    </FadeIn>
                  </Box>
                  <Box width="100%">
                    <FadeIn delay={500}>
                      <Heading size="large">Zrejme ste zablúdili</Heading>
                    </FadeIn>
                  </Box>
                </Box>
              </Container>
              <Container minHeight="auto" fluid>
                <FooterLayout size={size} />
              </Container>
              {size !== `small` && (
                <FadeIn delay={750}>
                  <Box
                    className="absolute"
                    width="100%"
                    style={{ bottom: `500px`, opacity: 0.1, left: `700px`, transform: `scale(2)` }}
                  >
                    <SlovaccLogoBlue />
                  </Box>
                </FadeIn>
              )}
            </Transition>
          </>
        )}
      </ResponsiveContext.Consumer>
    </GrommetWrapper>
  )
}

export default NotFoundPage
